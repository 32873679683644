'use client'

import { usePathname } from 'next/navigation'
import { useState } from 'react'
import type { FeatureProps } from '@patrianna-payments/shared-store/payments/types'
import { useAppSelector } from 'store/hooks'
import { getDailyBonusesSelector, getNextBonusTimeSelector } from 'store/modules/bonusesFlow/selectors'
import { getIsSpecialOffersVisible, getSortedSpecialOffers } from 'store/modules/shop/selectors'
import { getOffersRequestSelector } from 'store/modules/sessionDialogs/selectors'
import GameRowHeader from 'components/GameRowHeader'
import DailyReward from 'components/ShopTile/components/DailyReward'
import SpecialShopTile from 'components/ShopTile/components/SpecialShopTile'
import { trackEvent } from 'config/analytic'
import { useTranslation } from '@patrianna/core-components'
import classes from './styles.module.scss'
import { useAuth } from 'app/context/auth'
import Skeleton from './Skeleton'
import OffersNotAvailable from './OffersNotAvailable'
import ReferAFriendShopTile from 'components/ShopTile/components/ReferAFriendShopTile'
import { useCountryConfig } from 'app/context/country'
import { useInView } from 'src/hooks/useInView'
import { useSpecialOffersAmount } from 'hooks/useSpecialOffersAmount'
import { getDialogVisibilityByNameSelector } from 'store/modules/dialog/selectors'
import { Show } from 'components/Show'
import { B2Carousel } from '@patrianna/core-components'
import { parseISO } from 'date-fns'
import { useIsMobile } from '@patrianna/core-components/src/context/mobileDetectionContext'
import { ArrowIcon } from '../IconButton/components/ArrowIcon'

type Props = {
  largeTitle?: boolean
  isDailyBonusVisible?: boolean
  feature?: FeatureProps
}

export default function SpecialOffers({ largeTitle, feature, isDailyBonusVisible = false }: Props) {
  const offers = useAppSelector(getSortedSpecialOffers)
  const { isLoggedIn, isScEnabled } = useAuth()
  const nextBonusDate = useAppSelector(getNextBonusTimeSelector)
  const isSpecialOffersVisible = useAppSelector(getIsSpecialOffersVisible)
  const offersLoaded = useAppSelector(getOffersRequestSelector)
  const t = useTranslation()

  const pathname = usePathname()
  const [scrollPage, setScrollPage] = useState(1)
  const [freeCoinsTagVisible, setFreeCoinsTagVisible] = useState(true)
  const isMobile = useIsMobile()

  const isDailyBonusActive = new Date().getTime() > parseISO(nextBonusDate).getTime()
  const isFreeCoinsTagVisible = freeCoinsTagVisible && isDailyBonusVisible && isDailyBonusActive
  const isPromotionsPage = pathname === '/promotions'
  const dailyBonuses = useAppSelector(getDailyBonusesSelector)
  const showDailyReward = isLoggedIn && isDailyBonusVisible && Boolean(dailyBonuses?.length)
  const offersUnavailable = !offers.length || !isSpecialOffersVisible
  const { referFriendFeature } = useCountryConfig()
  const showReferAFriend = referFriendFeature.enabled && isScEnabled

  const extendedOffersCount = useSpecialOffersAmount()
  const isShopDialogOpened = useAppSelector((state) => getDialogVisibilityByNameSelector(state, 'SHOP_DIALOG'))
  const isShowHeader = !isPromotionsPage || isShopDialogOpened
  const { ref: dailyRewardRef } = useInView({
    threshold: 0.9,
    triggerOnce: true,
    initialInView: false,
    onChange: (inView) => {
      if (inView) {
        setFreeCoinsTagVisible(false)
      }
    },
  })

  const endScroll = () => {
    setScrollPage(extendedOffersCount)

    if (isScEnabled) {
      trackEvent('sc_daily_bonus_arrow_clicked', { category: 'daily SC arrow clicked' })
    } else {
      trackEvent('gc_daily_bonus_arrow_clicked', { category: 'daily GC arrow clicked' })
    }
  }

  if (isLoggedIn && !offersLoaded) {
    return <Skeleton isPromotionsPage={isPromotionsPage} />
  }

  if (offersUnavailable && !showDailyReward && !showReferAFriend) {
    return <OffersNotAvailable isPromotionsPage={isPromotionsPage} />
  }

  return (
    <>
      <Show when={isShowHeader}>
        <GameRowHeader
          title={t('common.title_offers')}
          freeCoinsTag={isFreeCoinsTagVisible}
          endScroll={endScroll}
          titleWithTag
          largeTitle={largeTitle}
        />
      </Show>
      <B2Carousel
        className={classes.arrowBtn}
        classNameContainer={classes.root}
        scrolledItemDataAttr='data-special-shop-tile'
        scrollPage={scrollPage}
        scrollToEndEnabled
        showArrows={!isMobile}
        ArrowIcon={ArrowIcon}
      >
        {!offersUnavailable &&
          offers.map((offer) => (
            <SpecialShopTile
              offer={offer}
              key={offer.code}
              scEnabled={isScEnabled}
              data-special-shop-tile
              feature={feature}
            />
          ))}
        {showReferAFriend && <ReferAFriendShopTile />}
        {showDailyReward && <DailyReward ref={dailyRewardRef} nextBonusDate={nextBonusDate} />}
      </B2Carousel>
    </>
  )
}
