import cx from 'classnames'
import classes from './styles.module.scss'
import { useTranslation } from '@patrianna/core-components'
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter'

interface Props {
  price: number
}

function OldPrice({ price }: Props) {
  const t = useTranslation()
  const { currencyFormatter } = useCurrencyFormatter()

  return (
    <div className={cx(classes.oldPrice, classes.sm_text)}>
      {t('common.was')} <span className={classes.lineThrough}>{currencyFormatter(price)}</span>
    </div>
  )
}

export default OldPrice
